const DateUtil = require("../common/DateUtil");
const MathUtil = require("../common/MathUtil")
const BaseFormCreator = require("./BaseFormCreator");
const path = require("path");
/**
 * 国土交通省の帳票を作成するクラス
 */
class ShizuokaFormCreator extends BaseFormCreator {
  constructor() {
    super()
  }
  makeFormData(selectedDataList, selectedTemplateInfo) {
    // 帳票出力用データを作成
    let formDataList = [];
    let iterateValueList = [];
    let formImageList = [];
    let photoValuelist = [];

    // 写真の出力開始行(とりあえず固定)
    let startRowMin = 4;
    let lastImageRow = 0;

    // 静的に配置するデータを取得
    let staticData = this.getStaticData(selectedDataList[0]);
    // グラフ生成テスト ここから
    staticData.push({
      key: "graph",
      value: ["test", "test2"],
      type: "graph",
    });
    // グラフ生成テスト ここまで
    for (let i = 0; i < selectedDataList.length; i++) {
      let commonAttribute = selectedDataList[i]
      let row = selectedDataList[i].rebarmeasure
      // let row = selectedDataList[i];
      // let row = selectedDataList[i].rebarmeasure

      //データA規格値
      let rebar1DesignRangeValue = this.getDesignRangeValue(
        row.designRangeClass,
        row.rebar1DiameterDesign,
        row.isMinusValidlyheight
      );
      //データA社内規格値
      let rebar1LocalDesignRangeValue = this.getLocalDesignRangeValue(
        row.localDesignRangeClass,
        row.rebar1DiameterDesign,
        rebar1DesignRangeValue,
        row.isMinusValidlyheight
      );
      //データB規格値
      let rebar2DesignRangeValue = this.getDesignRangeValue(
        row.designRangeClass,
        row.rebar2DiameterDesign,
        row.isMinusValidlyheight
      );
      //データB社内規格値
      let rebar2LocalDesignRangeValue = this.getLocalDesignRangeValue(
        row.localDesignRangeClass,
        row.rebar2DiameterDesign,
        rebar2DesignRangeValue,
        row.isMinusValidlyheight
      );

      console.log(
        `${rebar1LocalDesignRangeValue} ${rebar2LocalDesignRangeValue}`
      );
      let list = [];
      list.push({ key: "detailSpot", value: row.detailSpot, type: "string" });
      list.push({ key: "rebar1Memo", value: row.rebar1Memo ? row.rebar1Memo : "", type: "string" });
      list.push({
        key: "rebar1DiameterDesign",
        value: row.rebar1DiameterDesign ? `D${row.rebar1DiameterDesign}` : "",
        type: "string",
      });
      list.push({
        key: "rebar1PitchDesign",
        value: row.rebar1PitchDesign ? row.rebar1PitchDesign : "",
        type: "num",
      });
      list.push({
        key: "rebar1DesignRangeValue",
        value: rebar1DesignRangeValue ? rebar1DesignRangeValue : "",
        type: "string",
      });

      list.push({ key: "rebar2Memo", value: row.rebar2Memo ? row.rebar2Memo : "", type: "string" });
      list.push({
        key: "rebar2DiameterDesign",
        value: row.rebar2DiameterDesign ? `D${row.rebar2DiameterDesign}` : "",
        type: "string",
      });
      list.push({
        key: "rebar2PitchDesign",
        value: row.rebar2PitchDesign ? row.rebar2PitchDesign: "",
        type: "num",
      });
      list.push({
        key: "rebar2DesignRangeValue",
        value: rebar2DesignRangeValue ? rebar2DesignRangeValue : "",
        type: "string",
      });
      
      if (row.importType === 0) {
        if (row.pitchList1.length > 0) {
          list.push({
            key: "averagePitch1",
            value: this.invalidValueToEmpty(row.averagePitch1),
            type: "string",
          });
          list.push({
            key: "totalPitch1",
            value: row.totalPitch1,
            type: "num",
          });
          list.push({
            key: "pitchList1Num",
            value: row.pitchList1.length,
            type: "num",
          });
          list.push({
            key: "pitch1Diff",
            value: this.appendSign(MathUtil.minus(row.averagePitch1, row.rebar1PitchDesign)),
            type: "string",
          });
        }

        if (row.pitchList2.length > 0) {
          list.push({
            key: "averagePitch2",
            value: this.invalidValueToEmpty(row.averagePitch2),
            type: "string",
          });
          list.push({
            key: "totalPitch2",
            value: row.totalPitch2,
            type: "num",
          });
          list.push({
            key: "pitchList2Num",
            value: row.pitchList2.length,
            type: "num",
          });
          list.push({
            key: "pitch2Diff",
            value: this.appendSign(MathUtil.minus(row.averagePitch2, row.rebar2PitchDesign)),
            type: "string",
          });
        }
      } else {
        if (row.pitchList1.length > 0) {
          list.push({
            key: "rikkaiAveragePitch1",
            value: this.invalidValueToEmpty(row.averagePitch1),
            type: "string",
          });
          list.push({
            key: "rikkaiPitch1Diff",
            value: this.appendSign(MathUtil.minus(row.averagePitch1, row.rebar1PitchDesign)),
            type: "num",
          });
        }
        if (row.pitchList2.length > 0) {
          list.push({
            key: "rikkaiAveragePitch2",
            value: this.invalidValueToEmpty(row.averagePitch2),
            type: "string",
          });
          list.push({
            key: "rikkaiPitch2Diff",
            value: this.appendSign(MathUtil.minus(row.averagePitch2, row.rebar2PitchDesign)),
            type: "num",
          });
        }
      }
      iterateValueList.push({ list: list });

      const rowHeightNum = 17;
      const offSet = 18;
      let rowMin = 0;
      let rowMax = 0;
      startRowMin += lastImageRow;
      let photoValues = [];
      for (let j = 0; j < row.imageList.length; j++) {
        rowMin = startRowMin * (j + 1) + offSet * j;
        rowMax = rowMin + rowHeightNum;

        //撮影日をskから取得
        // let skItems = commonAttribute.sk.split("#");
        let measurementDate = row.title
        // let shootingDate = DateUtil.getDateString(row.title, 'YYYY年MM月DD日')
        let shootingDate = DateUtil.formatJPDateString(row.title, 'YYYY年MM月DD日')
        
        // 設計値の間隔合計
        const totalDesignPitch1 = MathUtil.multiply(row.rebar1PitchDesign, row.pitchList1.length)
        const totalDesignPitch2 = MathUtil.multiply(row.rebar2PitchDesign, row.pitchList2.length)

        photoValues.push({
          key: "measureTitle",
          value: row.importType == "0" ? "実測値" : "立会値",
          type: "string",
        });

        photoValues.push({
          key: "typeName",
          value: row.type == "0" ? "鉄筋工" : "床版工",
          type: "string",
        });
        photoValues.push({
          key: "constructionType",
          value: row.constructionType,
          type: "string",
        });
        photoValues.push({
          key: "shootingSpot",
          value: row.shootingSpot,
          type: "string",
        });
        photoValues.push({
          key: "placeName",
          value: row.detailSpot,
          type: "string",
        });
        photoValues.push({
          key: "rebar1No",
          value: row.rebar1No ? row.rebar1No : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar1DiameterDesign",
          value: row.rebar1DiameterDesign ? `D${row.rebar1DiameterDesign}` : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar1PitchDesign",
          value: row.rebar1PitchDesign ? row.rebar1PitchDesign : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar1Number",
          value: row.pitchList1.length,
          type: "string",
        });
        photoValues.push({
          key: "rebar2No",
          value: row.rebar2No ? row.rebar2No : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar2DiameterDesign",
          value: row.rebar2DiameterDesign ? `D${row.rebar2DiameterDesign}` : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar2PitchDesign",
          value: row.rebar2PitchDesign ? row.rebar2PitchDesign : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar2Number",
          value: row.pitchList2.length,
          type: "string",
        });
        photoValues.push({
          key: "averagePitch1",
          value: this.invalidValueToEmpty(row.averagePitch1),
          type: "string",
        });
        photoValues.push({
          key: "totalPitch1",
          value: this.invalidValueToEmpty(row.totalPitch1),
          type: "string",
        });
        photoValues.push({
          key: "totalDesignPitch1",
          value: totalDesignPitch1,
          type: "string",
        });
        photoValues.push({
          key: "averagePitch2",
          value: this.invalidValueToEmpty(row.averagePitch2),
          type: "string",
        });
        photoValues.push({
          key: "totalPitch2",
          value: this.invalidValueToEmpty(row.totalPitch2),
          type: "string",
        });
        photoValues.push({
          key: "totalDesignPitch2",
          value: totalDesignPitch2,
          type: "string",
        });
        photoValues.push({
          key: "measurementDate",
          value: measurementDate,
          type: "date",
        });
        photoValues.push({
          key: "shootingDate",
          value: shootingDate,
          type: "string",
        });

        const width = row.imageList[j].width ? row.imageList[j].width : 1364;
        const height = row.imageList[j].height ? row.imageList[j].height : 765;
        let key = row.imageList[j].isOrigin ? "originalImage" : "inspectImage";
        let imageS3Key = row.imageList[j].s3key
        let imageExt = path.extname(row.imageList[j].s3key)
        
        // 拡張子がsvgの場合はpngに変更して帳票出力する。pngファイルは連携時に作成済みの想定
        if (imageExt.toLowerCase() == ".svg") {
          let base = path.dirname(row.imageList[j].s3key)
          let fileName = path.basename(row.imageList[j].s3key, imageExt)
          imageS3Key = path.join(base, `${fileName}.png`)
        }

        photoValues.push({
          key: key,
          // value: `${row.imageList[j].s3key},${width},${height}`,
          value: `${imageS3Key},${width},${height}`,
          type: "image",
        });
        formImageList.push({
          imageId: "0",
          cellRange: `B${rowMin}:B${rowMax}`,
          width: 0,
          height: 0,
          s3Key: row.imageList[j].s3key,
        });
        
      }
      photoValuelist.push({ list: photoValues });
      lastImageRow = rowMax;
    }

    let currentDateString = this.getCurrentDateString("YYYYMMDDHHmmssSSS");
    this.formOutputData.formOutputSetting.templateFile = `${selectedTemplateInfo.template}`;
    this.formOutputData.formOutputSetting.outputFileName =
      currentDateString + "_" + selectedTemplateInfo.template;
    this.formOutputData.formOutputSetting.xlsxFileS3Key = "";
    this.formOutputData.formOutputSetting.workBookFileName = "";
    this.formOutputData.formOutputSetting.sheet = selectedTemplateInfo.sheet;
    this.formOutputData.formOutputSetting.imageSheet =
      selectedTemplateInfo.imageSheet;
    this.formOutputData.formOutputSetting.datalist = formDataList;
    this.formOutputData.formOutputSetting.imageList = formImageList;
    this.formOutputData.formOutputSetting.iterateValueList = iterateValueList;
    this.formOutputData.formOutputSetting.staticValueList = staticData;
    this.formOutputData.formOutputSetting.photoValuelist = photoValuelist;
    this.formOutputData.ttl = DateUtil.getTtl();

    //帳票送信時の画像パスにてsvgがある場合、拡張子をpngに変更する。
    // for (let k=0;k<this.formOutputData.formOutputSetting.imageList.length;k++)
    // {
    //   let file_type=path.extname(this.formOutputData.formOutputSetting.imageList[k].s3Key);
    //   let file_name=path.dirname(this.formOutputData.formOutputSetting.imageList[k].s3Key);
    //   if (file_type==".svg")
    //   {
    //     const filename = path.basename(this.formOutputData.formOutputSetting.imageList[k].s3Key, file_type);
    //     this.formOutputData.formOutputSetting.imageList[k].s3Key=path.join(file_name, filename+ ".png");
    //   }
    // }
  }
}

module.exports = ShizuokaFormCreator;