const moment = require("moment-timezone");
const MeasureFormModel = require("../../../appModel/rebar/MeasureFormModel");

const { API, Storage } = require("aws-amplify");
const appLog = require("../../../appUtils/AppLog");
class BaseFormCreator {
  constructor() {
    // DB登録用のオブジェクト
  }

  async setInitValue() {
    this.formOutputData = await MeasureFormModel.getNewData();
    console.log(
      `BaseFormCreator: ${JSON.stringify(this.formOutputData, null, "\t")}`
    );
  }
  /**
   * 配筋間隔の規格値取得
   * @param {*} designRangeClass
   * @param {*} designValue
   * @param {*} isMinusValidlyheight
   * @returns
   */
  getDesignRangeValue(designRangeClass, designValue, isMinusValidlyheight) {
    switch (designRangeClass) {
      case "0":
        //設計径
        return designValue;
      case "1":
        //プラスマイナス10
        return 10;
      case "2":
        //プラスマイナス20
        return 20;
      case "3":
        //有効高さがマイナスかどうかで可変
        return isMinusValidlyheight ? 10 : 20;
      default:
        return null;
    }
  }
  getKaburiDesignRangeList(designRangeClass, designRangeValue, designValue) {
    switch (designRangeClass) {
      case "0":
        // 未設定
        return "";
      case "1":
        if (designRangeValue) {
          // ±○mmかつ最小かぶり以上
          return `±${designRangeValue}mmかつ最小かぶり以上`;
        } else {
          return ``;
        }
      case "2":
        // 0以上+○mm以内
        if (designRangeValue) {
          return `0以上+${designRangeValue}mm以内`;
        } else {
          return ``;
        }
      case "3":
        // ±○mm以内
        if (designRangeValue) {
          return `±${designRangeValue}mm以内`;
        } else {
          return ``;
        }
      case "4":
        // 設計値以上
        return "設計値以上";
      default:
        // undefined or null
        return "";
    }
  }
  getLocalDesignRangeValue(
    localDesignRangeClass,
    designValue,
    designRangeValue,
    isMinusValidlyheight
  ) {
    switch (localDesignRangeClass) {
      case "0":
        //未選択
        return null;
      case "1":
        //±Φ(設計
        return designValue;
      case "2":
        //規格値の80%
        return Math.round(designRangeValue * 0.8);
      case "3":
        //規格値の90%
        return Math.round(designRangeValue * 0.9);
      case "4":
        //規格値の80%(±10)
        return isMinusValidlyheight ? 10 : Math.round(designRangeValue * 0.8);
      case "5":
        //規格値の90%(±10)
        return isMinusValidlyheight ? 10 : Math.round(designRangeValue * 0.9);
      default:
        return null;
    }
  }

  getStaticData(_row) {
    const row = _row.rebarmeasure;
    let staticData = [];

    //const typeName = row.type == "0" ? "鉄筋工" : "床版工"
    //工事名
    staticData.push({
      key: "constructionName",
      value: row.constructionName,
      type: "string",
    });
    //種別
    staticData.push({
      key: "constructionType",
      value: row.constructionType,
      type: "string",
    });
    //測点
    staticData.push({
      key: "shootingSpot",
      value: row.shootingSpot,
      type: "string",
    });
    //請負会社
    staticData.push({ key: "company", value: row.company, type: "string" });
    //測定者
    staticData.push({
      key: "measurerName",
      value: row.measurerName,
      type: "string",
    });
    //工種
    staticData.push({
      key: "typeName",
      value: row.type == "0" ? "鉄筋工" : "床版工",
      type: "string",
    });
    //詳細
    staticData.push({
      key: "detailNote",
      value: row.detailNote,
      type: "string",
    });
    //備考
    staticData.push({
      key: "memo",
      value: row.memo,
      type: "string",
    });

    return staticData;
  }
  invalidValueToEmpty(value) {
    if (!value && value !== 0 && value !== "0") {
      // if (!value) {
      return "";
    }
    if (isNaN(value)) {
      return 0;
    }
    return value;
  }
  appendSymbolAtFront(value, symbol) {
    if (!value) {
      return value;
    }
    return `${symbol} ${value}`;
  }
  appendSymbolAtBehind(value, symbol) {
    if (!value) {
      return value;
    }
    return `${value} ${symbol}`;
  }
  appendSign(value) {
    if (!value) {
      return value;
    }
    if (isNaN(value)) {
      return value;
    }
    let num = value - 0;
    if (num - 0 > 0) {
      // +を付けた文字列に
      return `+${num}`;
    } else {
      // マイナスがついているはず
      return `${num}`;
    }
  }
  //平均
  average(diff) {
    let sum = 0;
    let heikin;

    for (let i = 0; i < diff.length; i++) {
      sum += diff[i];
    }
    heikin = sum / diff.length;
    if (heikin > 0) {
      return `+${heikin.toFixed(1)}`;
    }
    return `${heikin.toFixed(1)}`;
  } //最大値

  max(diff) {
    let maxNumber = Math.max(...diff);

    if (maxNumber > 0) {
      return "+" + `${maxNumber}`;
    }
    return `${maxNumber}`;
  }

  //最小値
  min(diff) {
    let maxNumber = Math.min(...diff);

    if (maxNumber > 0) {
      return "+" + `${maxNumber}`;
    }
    return `${maxNumber}`;
  }

  //最多値
  mode(diff) {
    //重複削除
    const diffB = diff.filter((element, index) => {
      return diff.indexOf(element) == index;
    });

    let arr = [];
    //重複した数字を二次元配列の1列目に入れる。
    for (let i = 0; i < diffB.length; i++) {
      arr[i] = [];
      for (let j = 0; j < 2; j++) {
        if (j == 0) {
          arr[i][0] = diffB[i];
        } else if (j == 1) {
          //二次元配列の2列目に0を入れる。
          arr[i][1] = 0;
        }
      }
    }

    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < diff.length; j++) {
        if (arr[i][0] == diff[j]) {
          //重複する数字があったら二次元配列の2列目を足してく
          arr[i][1] = arr[i][1] + 1;
        }
      }
    }

    //最多値を出す。
    let syutugen = 0,
      saitati = 0;
    for (let i = 0; i < arr.length; i++) {
      if (syutugen < arr[i][1]) {
        syutugen = arr[i][1];
        saitati = arr[i][0];
      }
    }
    if (syutugen > 1) {
      if (saitati > 0) {
        // +を付けた文字列に
        return `+${saitati}`;
      } else {
        return `${saitati}`;
      }
    } else if (syutugen == 1) {
      return "";
    }
  }
  //標準偏差
  standardDeviation(diff) {
    console.log(ave);
    let bunsan = [];
    let difference;
    let sum2 = 0;
    let ave;

    //平均出す
    for (let i = 0; i < diff.length; i++) {
      sum2 += diff[i];
    }
    ave = sum2 / diff.length;

    //｛（各データ）－（平均値）｝2をする
    for (let i = 0; i < diff.length; i++) {
      difference = diff[i] - ave;
      bunsan.push(difference * difference);
    }
    let sum = 0;

    //｛（各データ）－（平均値）｝2の和を出す
    for (let i = 0; i < bunsan.length; i++) {
      sum += bunsan[i];
    }
    //全体の人数で割ってルートを被せる。
    return Math.sqrt(sum / bunsan.length).toFixed(1);
  }

  getCurrentDateString(format) {
    return moment(new Date()).tz("Asia/Tokyo").format(format);
  }
  /**
   * 帳票の入力データをJSONで作成し、S3にアップロードする
   * @param {*} key
   */
  async putFormDataJson() {
    // 帳票出力データをJSONリテラルに
    const jsonData = JSON.stringify(this.formOutputData);
    console.log(jsonData, null, "\t");
    // バイナリ化
    const blob = new Blob([jsonData], { type: "application/json" });
    // DBのユニークidをキーにする
    const formDataJsonKey = `formData/${this.formOutputData.sk}.json`;
    // S3アップロード
    await Storage.put(formDataJsonKey, blob, {
      level: "private",
      contentType: "application/json",
    });
  }

  //グラフ画像表示Json作成
  createJson(diff, spot, kikakuti) {
    //差の最大値を出す
    let max = parseFloat(this.max(diff)).toFixed(0);
    max = parseInt(max) + 10;
    //差の最小値を出す
    let min = parseFloat(this.min(diff)).toFixed(0);
    min = parseInt(min) - 10;

    let pluskikakuti = parseFloat(kikakuti);
    pluskikakuti =Math.round(pluskikakuti * 10) / 10;
    // console.log(isNaN(kikakuti));
    // if (isNaN(kikakuti) == true) {
    //   kikakuti = 20;
    // }
    let mainasukikakuti = -pluskikakuti;
    //規格値80%
    let max80=Math.round((parseInt(pluskikakuti) * 0.8) * 10) / 10 ;
    let min80=Math.round((parseInt(mainasukikakuti) * 0.8) * 10) / 10 ;

    //規格値50%
    let max50=Math.round((parseInt(pluskikakuti) * 0.5) * 10) / 10 ;
    let min50=Math.round((parseInt(mainasukikakuti) * 0.5) * 10) / 10 ;

    //差の最大値と規格値を比較して大きい方がグラフ項目の最大値とする。
    if (pluskikakuti > max) {
      max = pluskikakuti + 10;
    }
    //差の最小値と規格値を比較して小さい方がグラフ項目の最小値とする。
    if (mainasukikakuti < min) {
      min = mainasukikakuti - 10;
    }
    let myChart = {
      type: "line",
      data: {
        labels: spot,
        datasets: [
          {
            backgroundColor: "rgba(30,160,233,1)",
            label: "difference",
            data: diff,
            borderColor: "rgba(0,0,0,1)",
            xAxisID: "鉄筋名",
            yAxisID: "差異",
            pointRadius: 8
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          差異: {
            ticks: {
              color: "rgba(30,30,30,1.0)",
              display: true,
              major: {
                enabled: true,
              },
              font: {
                size: 18,
              },
            },
            min: min,
            max: max,
          },
          鉄筋名: {
            ticks: {
              color: "rgba(30,30,30,1.0)",
              font: {
                size: 18,
                family: "IPAexGothic",
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
            labels: {
              color: "rgba(30,30,30,1.0)",
            },
          },
          // ツールチップ
          tooltip: {
            backgroundColor: "#933",
          },
          drawTime: "beforeDraw",
          annotation: {
            annotations: [
              {
                type: "line", // 線分を指定
                mode: "horizontal", // 水平を指定
                scaleID: "差異", // 基準とする軸のid名
                value: max50, // 引きたい線の数値（始点）
                endValue: max50, // 引きたい線の数値（終点）
                borderColor: "rgba(100,100,100,1)", // 線の色
                borderWidth: 1, // 線の幅（太さ）
                borderDash: [2, 2],
                borderDashOffset: 1,
                label: {
                  // ラベルの設定
                  backgroundColor: "rgba(100,100,100,0.5)",
                  content: `${max50}(50%)`,
                  position: "start",
                  display: true,
                  padding: 1,
                  font: {
                    size: 20,
                    weight: "normal",
                    style: "normal",
                  },
                },
              },
              {
                type: "line", // 線分を指定
                mode: "horizontal", // 水平を指定
                scaleID: "差異", // 基準とする軸のid名
                value: max80, // 引きたい線の数値（始点）
                endValue: max80, // 引きたい線の数値（終点）
                borderColor: "rgba(196,20,255,1)", // 線の色
                borderWidth: 1, // 線の幅（太さ）
                borderDash: [2, 2],
                borderDashOffset: 1,
                label: {
                  // ラベルの設定
                  backgroundColor: "rgba(196,20,255,0.5)",
                  content: `${max80}(80%)`,
                  position: "start",
                  display: true,
                  padding: 1,
                  font: {
                    size: 20,
                    weight: "normal",
                    style: "normal",
                  },
                },
              },
              {
                type: "line", // 線分を指定
                mode: "horizontal", // 水平を指定
                scaleID: "差異", // 基準とする軸のid名
                value: pluskikakuti, // 引きたい線の数値（始点）
                endValue: pluskikakuti, // 引きたい線の数値（終点）
                borderColor: "rgba(217,9,19,1)", // 線の色
                borderWidth: 1, // 線の幅（太さ）
                borderDash: [2, 2],
                borderDashOffset: 1,
                label: {
                  // ラベルの設定
                  backgroundColor: "rgba(217,9,19,0.5)",
                  //content: '20 (100%)',
                  content: `${pluskikakuti}(100%)`,
                  position: "start",
                  display: true,
                  padding: 1,
                  font: {
                    size: 20,
                    weight: "normal",
                    style: "normal",
                  },
                },
              },
              {
                type: "line", // 線分を指定
                mode: "horizontal", // 水平を指定
                scaleID: "差異", // 基準とする軸のid名
                value: min50, // 引きたい線の数値（始点）
                endValue: min50, // 引きたい線の数値（終点）
                borderColor: "rgba(100,100,100,1)", // 線の色
                borderWidth: 1, // 線の幅（太さ）
                borderDash: [2, 2],
                label: {
                  // ラベルの設定
                  backgroundColor: "rgba(100,100,100,0.5)",
                  content: `${min50}(50%)`,
                  position: "start",
                  display: true,
                  padding: 1,
                  font: {
                    size: 20,
                    weight: "normal",
                    style: "normal",
                  },
                },
              },
              {
                type: "line", // 線分を指定
                mode: "horizontal", // 水平を指定
                scaleID: "差異", // 基準とする軸のid名
                value: min80, // 引きたい線の数値（始点）
                endValue: min80, // 引きたい線の数値（終点）
                borderColor: "rgba(196,20,255,1)", // 線の色
                borderWidth: 1, // 線の幅（太さ）
                borderDash: [2, 2],
                label: {
                  // ラベルの設定
                  backgroundColor: "rgba(196,20,255,0.5)",
                  content: `${min80}(80%)`,
                  position: "start",
                  display: true,
                  padding: 1,
                  font: {
                    size: 20,
                    weight: "normal",
                    style: "normal",
                  },
                },
              },
              {
                type: "line", // 線分を指定
                mode: "horizontal", // 水平を指定
                scaleID: "差異", // 基準とする軸のid名
                value: mainasukikakuti, // 引きたい線の数値（始点）
                endValue: mainasukikakuti, // 引きたい線の数値（終点）
                borderColor: "rgba(217,9,19,1)", // 線の色
                borderWidth: 1, // 線の幅（太さ）
                borderDash: [2, 2],
                borderDashOffset: 1,
                label: {
                  // ラベルの設定
                  backgroundColor: "rgba(217,9,19,0.5)",
                  content: `${mainasukikakuti}(100%)`,
                  position: "start",
                  display: true,
                  padding: 1,
                  font: {
                    size: 20,
                    weight: "normal",
                    style: "normal",
                  },
                },
              },
            ],
          },
        },
      },
    };

    return myChart;
  }

  //JSONアップロード処理
  async uploadJson(list, jsonname) {
    // 帳票出力データをJSONリテラルに
    const jsonData = JSON.stringify(list);
    console.log(jsonData, null, "\t");
    // バイナリ化
    const blob = new Blob([jsonData], { type: "application/json" });
    console.log(blob);
    // DBのユニークidをキーにする
    const formDataJsonKey = `graphData/${jsonname}.json`;
    // // S3アップロード
    await Storage.put(formDataJsonKey, blob, {
      level: "private",
      contentType: "application/json",
    });
    console.log("1");
  }

  async invokeFormApi() {
    try {
      // jsonファイルを登録
      await this.putFormDataJson();

      // DB登録
      // await MeasureFormModel.addFormData(this.formOutputData)
      appLog.debugLog("登録OK");
      // API呼び出しとダウンロード
      return await this.callSmcMakeXlsxForm();
    } catch (e) {
      console.log(
        `帳票作成API呼び出し時のエラー ${JSON.stringify(e, null, "\t")}`
      );
      throw e;
    }
  }
  async callSmcMakeXlsxForm() {
    try {
      const APIName = "apiexcelform";
      const path = "/excelform";
      const myInit = {
        headers: {},
        response: true,
        body: {
          pk: this.formOutputData.pk,
          sk: this.formOutputData.sk,
        },
      };

      const result = await API.post(APIName, path, myInit);
      if (!result.data) {
        throw new Error(`帳票作成失敗`);
      }
      this.apiResult = result;

      const outFileName = `xlsxform/${result.data.formFileName}`;
      const excelUrl = await Storage.get(outFileName, { level: "private" });
      //await Storage.get(outFileName, {level: "private"})
      console.log(`excelUrl:${excelUrl}`);
      return excelUrl;
    } catch (e) {
      console.error(`Error: ${e}`);
      throw e;
    }
  }
}
module.exports = BaseFormCreator;
