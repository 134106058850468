const DateUtil = require("../common/DateUtil");
const BaseFormCreator = require("./BaseFormCreator");
const MathUtil = require("../common/MathUtil");
const path = require("path");
const { v4: uuidv4 } = require("uuid");

/**
 * 国土交通省の帳票を作成するクラス
 */
class MiltFormCreator4 extends BaseFormCreator {
  constructor() {
    super();
  }
  makeFormData(selectedDataList, selectedTemplateInfo) {
    // 帳票出力用データを作成
    let formDataList = [];
    let iterateValueList = [];

    let formImageList = [];
    let photoValuelist = [];

    // 写真の出力開始行(とりあえず固定)
    let startRowMin = 4;
    let lastImageRow = 0;

    // 静的に配置するデータを取得
    let staticData = this.getStaticData(selectedDataList[0]);

    let list = [];
    //差の帳票データ出力用
    let diff = [];
    //差の帳票データ出力用(差の平均値、標準偏差など求めるためのリスト)
    let diff2 = [];
    //測点の帳票データ出力用
    let spot = [];
    //設計値の帳票データ出力用
    let design = [];
    //実測値の帳票データ出力用
    let value = [];
    //帳票データ出力のカウントで使用
    let count = 0;
    //床版工平均間隔のフラグ
    let type;
    //床版工平均間隔のフラグ（1件目のみ）
    let type1;

    //規格値リスト
    let kikakuti = [];

    //帳票データ1ページ目の最大件数
    const firstPageMax = 20;
    //帳票データ2ページ目以降の最大件数
    const secondPageOnwards = 30;

    list.push({
      key: `No1row1spot`,
      value: ``,
      type: "string",
    });
    list.push({
      key: `No1row2spot`,
      value: `平均値`,
      type: "string",
    });
    list.push({
      key: `No1row3spot`,
      value: `最大値`,
      type: "string",
    });
    list.push({
      key: `No1row4spot`,
      value: `最小値`,
      type: "string",
    });
    list.push({
      key: `No1row5spot`,
      value: `最多値`,
      type: "string",
    });
    list.push({
      key: `No1row7spot`,
      value: `標準偏差`,
      type: "string",
    });

    let prefixNum = 2;
    let rebar1Design = "";
    let rebar1localDesign = "";

    for (let i = 0; i < selectedDataList.length; i++) {
      let row = selectedDataList[i].rebarmeasure;
      type = row.type;
      if (i == 0) {
        type1 = row.type;
      }

      //データA規格値
      let rebar1DesignRangeValue = this.getDesignRangeValue(
        row.designRangeClass,
        row.rebar1DiameterDesign,
        row.isMinusValidlyheight
      );
      //データA社内規格値
      let rebar1LocalDesignRangeValue = this.getLocalDesignRangeValue(
        row.localDesignRangeClass,
        row.rebar1DiameterDesign,
        rebar1DesignRangeValue,
        row.isMinusValidlyheight
      );
      //データB規格値
      let rebar2DesignRangeValue = this.getDesignRangeValue(
        row.designRangeClass,
        row.rebar2DiameterDesign,
        row.isMinusValidlyheight
      );

      kikakuti.push(rebar1DesignRangeValue);
      kikakuti.push(rebar2DesignRangeValue);

      //床版工データABの順で件数分ループ
      //データA
      if (type == 1) {
        row.pitchList1.forEach((pitch) => {
          spot.push(row.shootingSpot+ " " + row.rebar1No);

          design.push(row.rebar1PitchDesign ? row.rebar1PitchDesign : ``);

          diff.push(`${this.appendSign(`${(pitch.value - row.rebar1PitchDesign).toFixed(1)}`)}`);

          diff2.push(Number(this.appendSign(`${(pitch.value - row.rebar1PitchDesign).toFixed(1)}`)));

          value.push(`${pitch.value}`);
        });

        //データB(データBが0件だったら飛ばす)
        if(row.pitchList2.length > 0){
          row.pitchList2.forEach((pitch) => {
            spot.push(row.shootingSpot+ " " + row.rebar2No);

            design.push(row.rebar2PitchDesign ? row.rebar2PitchDesign : ``);

            diff.push(`${this.appendSign(`${(pitch.value - row.rebar2PitchDesign).toFixed(1)}`)}`);

            diff2.push(Number(this.appendSign(`${(pitch.value - row.rebar2PitchDesign).toFixed(1)}`)));

            value.push(`${pitch.value}`);
          });
        }
      }

      //平均間隔データABの平均値
      if (type == 0) {
        //データA
        spot.push(row.shootingSpot+ " " + row.rebar1No);
        design.push(row.rebar1PitchDesign ? row.rebar1PitchDesign : ``);
        diff.push(`${(row.averagePitch1 - row.rebar1PitchDesign).toFixed(1)}`);
        diff2.push(Number(this.appendSign(`${(row.averagePitch1 - row.rebar1PitchDesign).toFixed(1)}`)));
        value.push(`${row.averagePitch1}`);

        //データB(データBが0件だったら飛ばす)
        
        if (row.averagePitch2 != null){
          if (row.averagePitch2 > 0) {
            spot.push(row.shootingSpot+ " " + row.rebar2No);
            design.push(row.rebar2PitchDesign ? row.rebar2PitchDesign : ``);
            diff.push(`${(row.averagePitch2 - row.rebar2PitchDesign).toFixed(1)}`);
            diff2.push(Number(this.appendSign(`${(row.averagePitch2 - row.rebar2PitchDesign).toFixed(1)}`)));
            value.push(`${row.averagePitch2}`);
          }
        }
      }

      if (i == 0) {
        rebar1Design = rebar1DesignRangeValue;
        rebar1localDesign = rebar1LocalDesignRangeValue;

        //規格値が空の場合、mmは表示しない
        if (rebar1DesignRangeValue != null) {
          list.push({
            key: `miri`,
            value: "mm",
            type: "string",
          });
        }else {
          list.push({
            key: `miri`,
            value: "",
            type: "string",
          });
        }
        //社会規格値が空の場合、mmは表示しない
        if (rebar1LocalDesignRangeValue != null) {
          list.push({
            key: `miri2`,
            value: "mm",
            type: "string",
          });
        }else {
          list.push({
            key: `miri2`,
            value: "",
            type: "string",
          });
        }

        list.push({
          key: `No1Rebar1DesignRangeValue`,
          value: this.appendSymbolAtFront(
            this.invalidValueToEmpty(rebar1DesignRangeValue),
            "±"
          ),
          type: "string",
        });
        list.push({
          key: `No1Rebar1LocalDesignRangeValue`,
          value: this.appendSymbolAtFront(
            this.invalidValueToEmpty(rebar1LocalDesignRangeValue),
            "±"
          ),
          type: "string",
        });
        if (type == 1) {
          list.push({
            key: `No1Rebar1MeasurementItemName`,
            value: `各鉄筋間隔`,
            type: "string",
          });
        } else {
          list.push({
            key: `No1Rebar1MeasurementItemName`,
            value: `平均間隔`,
            type: "string",
          });
        }
      }

      const rowHeightNum = 17;
      const offSet = 18;
      let rowMin = 0;
      let rowMax = 0;
      startRowMin += lastImageRow;
      let photoValues = [];
      for (let j = 0; j < row.imageList.length; j++) {
        rowMin = startRowMin * (j + 1) + offSet * j;
        rowMax = rowMin + rowHeightNum;

        //撮影日をskから取得
        let measurementDate = row.title;
        let shootingDate = DateUtil.formatJPDateString(
          row.title,
          "YYYY年MM月DD日"
        );

        // 設計値の間隔合計
        const totalDesignPitch1 = MathUtil.multiply(
          row.rebar1PitchDesign,
          row.pitchList1.length
        );
        const totalDesignPitch2 = MathUtil.multiply(
          row.rebar2PitchDesign,
          row.pitchList2.length
        );

        photoValues.push({
          key: "measureTitle",
          value: row.importType == "0" ? "実測値" : "立会値",
          type: "string",
        });

        photoValues.push({
          key: "typeName",
          value: row.type == "0" ? "鉄筋工" : "床版工",
          type: "string",
        });
        photoValues.push({
          key: "constructionType",
          value: row.constructionType ? row.constructionType : ``,
          type: "string",
        });
        photoValues.push({
          key: "shootingSpot",
          value: row.shootingSpot,
          type: "string",
        });
        photoValues.push({
          key: "placeName",
          value: row.detailSpot,
          type: "string",
        });
        photoValues.push({
          key: "rebar1No",
          value: row.rebar1No ? row.rebar1No : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar1DiameterDesign",
          value: row.rebar1DiameterDesign ? `D${row.rebar1DiameterDesign}` : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar1PitchDesign",
          value: row.rebar1PitchDesign ? row.rebar1PitchDesign : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar1Number",
          value: row.pitchList1 ? row.pitchList1.length : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar2No",
          value: row.rebar2No ? row.rebar2No : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar2DiameterDesign",
          value: row.rebar2DiameterDesign ? `D${row.rebar2DiameterDesign}` : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar2PitchDesign",
          value: row.rebar2PitchDesign ? row.rebar2PitchDesign : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar2Number",
          value: row.pitchList2 ? row.pitchList2.length : ``,
          type: "string",
        });
        photoValues.push({
          key: "averagePitch1",
          value: this.invalidValueToEmpty(row.averagePitch1),
          type: "string",
        });
        photoValues.push({
          key: "totalPitch1",
          value: this.invalidValueToEmpty(row.totalPitch1),
          type: "string",
        });
        photoValues.push({
          key: "totalDesignPitch1",
          value: totalDesignPitch1,
          type: "string",
        });
        photoValues.push({
          key: "averagePitch2",
          value: this.invalidValueToEmpty(row.averagePitch2),
          type: "string",
        });
        photoValues.push({
          key: "totalPitch2",
          value: this.invalidValueToEmpty(row.totalPitch2),
          type: "string",
        });
        photoValues.push({
          key: "totalDesignPitch2",
          value: totalDesignPitch2,
          type: "string",
        });
        photoValues.push({
          key: "measurementDate",
          value: measurementDate,
          type: "date",
        });
        photoValues.push({
          key: "shootingDate",
          value: shootingDate,
          type: "string",
        });
        // 規格値
        photoValues.push({
          key: `designRangeValue`,
          value: this.appendSymbolAtFront(
            this.invalidValueToEmpty(rebar1DesignRangeValue),
            "±"
          ),
          type: "string",
        });
        // 有効高さマイナス
        photoValues.push({
          key: `isMinusValidlyheight`,
          value: row.isMinusValidlyheight ? "有効高さがマイナスのため" : "",
          type: "string",
        });

        const width = row.imageList[j].width ? row.imageList[j].width : 1364;
        const height = row.imageList[j].height ? row.imageList[j].height : 765;
        let key = row.imageList[j].isOrigin ? "originalImage" : "inspectImage";

        // 拡張子がsvgの場合はpngに変更して帳票出力する。pngファイルは連携時に作成済みの想定
        let imageS3Key = row.imageList[j].s3key;
        let imageExt = path.extname(row.imageList[j].s3key);

        if (imageExt.toLowerCase() == ".svg") {
          let base = path.dirname(row.imageList[j].s3key);
          let fileName = path.basename(row.imageList[j].s3key, imageExt);
          imageS3Key = path.join(base, `${fileName}.png`);
        }

        photoValues.push({
          key: key,
          value: `${imageS3Key},${width},${height}`,
          type: "image",
        });

        // 備考
        photoValues.push({
          key: `memo`,
          value: row.memo ? row.memo : ``,
          type: "string",
        });

        formImageList.push({
          imageId: "0",
          cellRange: `B${rowMin}:B${rowMax}`,
          width: 0,
          height: 0,
          s3Key: row.imageList[j].s3key,
        });
      }
      photoValuelist.push({ list: photoValues });
      lastImageRow = rowMax;
    }
    let graphCount = 1;

    //差の値に+-付ける処理
    let diff3 = [];
    for (let i = 0; i < diff.length; i++) {
      diff3.push(`${this.appendSign(`${diff[i]}`)}`);
    }

    let datakensu = -1;
    let datapage = 0;

    //帳票データ出力
    for (let k = 0; k < diff.length; k++) {
      let dataprefix = `No${prefixNum}`;

      //countが0の場合は差の結果を出力
      if (count == 0) {
        //平均
        list.push({
          key: `No1row2diff`,
          value: this.average(diff2),
          type: "string",
        });
        //最大値
        list.push({
          key: `No1row3diff`,
          value: this.max(diff2),
          type: "string",
        });
        //最小値
        list.push({
          key: `No1row4diff`,
          value: this.min(diff2),
          type: "string",
        });
        //最多値
        list.push({
          key: `No1row5diff`,
          value: this.mode(diff2),
          type: "string",
        });
        list.push({
          key: `No1row6diff`,
          value: "n=" + diff2.length,
          type: "string",
        });
        //標準偏差
        list.push({
          key: `No1row7diff`,
          value: "m=±" + this.standardDeviation(diff2),
          type: "string",
        });
        count += 1;
      }

      //グラフ項目
      list.push({
        key: `No2row${graphCount}graph`,
        value: `${spot[k]}`,
        type: "string",
      });
      graphCount += 1;

      //実測値
      list.push({
        key: `${dataprefix}row${count}value`,
        value: `${value[k]}`,
        type: "string",
      });

      //測点
      list.push({
        key: `${dataprefix}row${count}spot`,
        value: spot[k],
        type: "string",
      });

      //設計値
      list.push({
        key: `${dataprefix}row${count}Design`,
        value: `${design[k]}`,
        type: "num",
      });

      //差
      list.push({
        key: `${dataprefix}row${count}diff`,
        value: `${diff3[k]}`,
        type: "string",
      });

      datakensu += 1;
      //1ページ目の帳票データ件数19(0～19)
      const firstDataPage = 19;

      //データ件数の1の位が9又はループ件数とデータ件数が一致したら改行
      if (k % 10 == 9 || k + 1 == diff.length) {
        //1ページ目データ件数が20件又は２ページ目以降の件数が30件又はループ件数とデータ件数が一致したらページ改行する。
        if (
          k == firstDataPage ||
          (datakensu == secondPageOnwards && datapage == 1) ||
          k + 1 == diff.length
        ) {
          list.push({
            key: `No1Rebar1DesignRangeValue`,
            value: this.appendSymbolAtFront(
              this.invalidValueToEmpty(rebar1Design),
              "±"
            ),
            type: "string",
          });
          list.push({
            key: `No1Rebar1LocalDesignRangeValue`,
            value: this.appendSymbolAtFront(
              this.invalidValueToEmpty(rebar1localDesign),
              "±"
            ),
            type: "string",
          });

          if (rebar1Design != null) {
            list.push({
              key: `miri`,
              value: "mm",
              type: "string",
            });
          }else {
            list.push({
              key: `miri`,
              value: "",
              type: "string",
            });
          }
          if (rebar1localDesign != null) {
            list.push({
              key: `miri2`,
              value: "mm",
              type: "string",
            });
          }else {
            list.push({
              key: `miri2`,
              value: "",
              type: "string",
            });
          }

          if (type1 == 0) {
            //平均間隔
            list.push({
              key: `No1Rebar1MeasurementItemName`,
              value: `平均間隔`,
              type: "string",
            });
          } else {
            // 床板工
            list.push({
              key: `No1Rebar1MeasurementItemName`,
              value: `各鉄筋間隔`,
              type: "string",
            });
          }

          iterateValueList.push({ list: list });

          list = [];
          count = 0;
          graphCount = 1;
          prefixNum = 1;
          datakensu = 0;
          if (datapage == 0) {
            //2ページ目以降はdatapageを1にする。
            datapage += 1;
          }
        } else {
          //データの改行
          prefixNum += 1;
          count = 0;
        }
      }

      count += 1;
    }

    const mojisu = 15;
    //グラフ画像に表示する長文字をチェックする。
    for (let i = 0; i < spot.length; i++) {
      if (spot[i].length > mojisu) {
        //15文字超えたら文字末尾...にする。
        spot[i] = spot[i].substring(0, mojisu) + "...";
      }
    }

    //グラフ画像json作成
    //グラフ差のリスト
    let graphdiff = [];
    //グラフ測点名リスト
    let graphspot = [];
    //グラフ画像リスト
    let graph = [];

    //ページ数フラグ
    let page = 0;

    for (let i = 0; i < diff2.length; i++) {
      graphdiff.push(diff2[i]);
      graphspot.push(spot[i]);

      //ページごとにグラフ画像jsonを作成する。
      //1ページ目データ件数が20件又は２ページ目以降の件数が30件又はループ件数とデータ件数が一致したらページ改行して
      //新しいグラフ画像jsonを作成する。
      if (
        (graphdiff.length == firstPageMax && page == 0) ||
        (graphdiff.length == secondPageOnwards && page > 0) ||
        i + 1 == diff2.length
      ) {
        //グラフ作成用JSONパラメータ作成処理
        let myChart = this.createJson(graphdiff, graphspot, rebar1Design);

        //json名作成
        let jsonname = uuidv4();

        //JSONアップロード処理
        this.uploadJson(myChart, jsonname);

        graph.push(`${jsonname}`);

        graphdiff = [];
        graphspot = [];

        page += 1;
      }
    }

    staticData.push({
      key: "graph",
      value: graph,
      type: "graph",
    });

    if (list.length > 0) {
      iterateValueList.push({ list: list });
      list = [];
    }

    let currentDateString = this.getCurrentDateString("YYYYMMDDHHmmssSSS");

    this.formOutputData.formOutputSetting.templateFile = `${selectedTemplateInfo.template}`;
    this.formOutputData.formOutputSetting.outputFileName =
      currentDateString + "_" + selectedTemplateInfo.template;
    this.formOutputData.formOutputSetting.xlsxFileS3Key = "";
    this.formOutputData.formOutputSetting.workBookFileName = "";
    this.formOutputData.formOutputSetting.sheet = selectedTemplateInfo.sheet;
    this.formOutputData.formOutputSetting.imageSheet =
      selectedTemplateInfo.imageSheet;
    this.formOutputData.formOutputSetting.datalist = formDataList;
    this.formOutputData.formOutputSetting.imageList = formImageList;
    this.formOutputData.formOutputSetting.iterateValueList = iterateValueList;
    this.formOutputData.formOutputSetting.staticValueList = staticData;
    this.formOutputData.formOutputSetting.photoValuelist = photoValuelist;
    this.formOutputData.ttl = DateUtil.getTtl();
    console.log("");
    //帳票送信時の画像パスにてsvgがある場合、拡張子をpngに変更する。
    // for (let k=0;k<this.formOutputData.formOutputSetting.imageList.length;k++)
    // {
    //   let file_type = path.extname(this.formOutputData.formOutputSetting.imageList[k].s3Key);
    //   let file_name = path.dirname(this.formOutputData.formOutputSetting.imageList[k].s3Key);
    //   if (file_type == ".svg")
    //   {
    //     const filename = path.basename(this.formOutputData.formOutputSetting.imageList[k].s3Key, file_type);
    //     this.formOutputData.formOutputSetting.imageList[k].s3Key = path.join(file_name, filename+ ".png");
    //   } else {

    //   }
    // }
  }
}

module.exports = MiltFormCreator4;
