const DateUtil = require("../common/DateUtil");
const BaseFormCreator = require("./BaseFormCreator");
const MathUtil = require("../common/MathUtil")
const path = require("path");
/**
 * 国土交通省の帳票を作成するクラス
 */
class MiltFormCreator extends BaseFormCreator {
  constructor() {
    super()
  }
  makeFormData(selectedDataList, selectedTemplateInfo) {
    // 帳票出力用データを作成
    let formDataList = [];
    let iterateValueList = [];

    let formImageList = [];
    let photoValuelist = [];

    // 写真の出力開始行(とりあえず固定)
    let startRowMin = 4;
    let lastImageRow = 0;

    // 静的に配置するデータを取得
    let staticData = this.getStaticData(selectedDataList[0]);

    let list = [];

    for (let i = 0; i < selectedDataList.length; i++) {
      // let row = selectedDataList[i];
      let commonAttribute = selectedDataList[i]
      let row = selectedDataList[i].rebarmeasure

      let prefixNum = 4 - (4 - (i % 4)) + 1;
      let prefix = `No${prefixNum}`;

      //データA規格値
      let rebar1DesignRangeValue = this.getDesignRangeValue(
        row.designRangeClass,
        row.rebar1DiameterDesign,
        row.isMinusValidlyheight
      );
      //データA社内規格値
      let rebar1LocalDesignRangeValue = this.getLocalDesignRangeValue(
        row.localDesignRangeClass,
        row.rebar1DiameterDesign,
        rebar1DesignRangeValue,
        row.isMinusValidlyheight
      );
      //データB規格値
      let rebar2DesignRangeValue = this.getDesignRangeValue(
        row.designRangeClass,
        row.rebar2DiameterDesign,
        row.isMinusValidlyheight
      );
      //データB社内規格値
      let rebar2LocalDesignRangeValue = this.getLocalDesignRangeValue(
        row.localDesignRangeClass,
        row.rebar2DiameterDesign,
        rebar2DesignRangeValue,
        row.isMinusValidlyheight
      );

      if (row.type == "0") {
        // 鉄筋工

        if (row.pitchList1) {
          if (row.pitchList1.length > 0) {
            list.push({
              key: `${prefix}Rebar1MeasurementItemName`,
              value: `平均間隔`,
              type: "string",
            });
            list.push({
              key: `${prefix}detailSpot`,
              value: `${row.detailSpot}`,
              type: "string",
            });
            list.push({
              key: `${prefix}Rebar1Memo`,
              value: row.rebar1Memo ? row.rebar1Memo : ``,
              type: "string",
            });
            list.push({
              key: `${prefix}Rebar1No`,
              value: row.rebar1No ? row.rebar1No : ``,
              type: "string",
            });
            list.push({
              key: `${prefix}Rebar1DiameterDesign`,
              value: row.rebar1DiameterDesign
                ? `(D${row.rebar1DiameterDesign})`
                : ``,
              type: "string",
            });
            list.push({
              key: `${prefix}Rebar1DesignRangeValue`,
              value: this.appendSymbolAtFront(this.invalidValueToEmpty(rebar1DesignRangeValue), "±"),
              type: "string",
            });
            list.push({
              key: `${prefix}Rebar1LocalDesignRangeValue`,
              value: this.appendSymbolAtFront(this.invalidValueToEmpty(rebar1LocalDesignRangeValue), "±"),
              type: "string",
            });
            list.push({
              key: `${prefix}Rebar1PitchDesign`,
              value: row.rebar1PitchDesign ? row.rebar1PitchDesign : ``,
              type: "num",
            });
            list.push({
              key: `${prefix}Rebar1PitchDesignAverage`,
              value: row.rebar1PitchDesign ? row.rebar1PitchDesign : ``,
              type: "num",
            });
            //鉄筋工合計、平均のみ表示する
            if (row.rebar1PitchDesign) {
              list.push({
                key: `${prefix}Rebar1TotalPitchDesign`,
                value: `${row.pitchList1.length * row.rebar1PitchDesign}`,
                type: "string",
              });
              list.push({
                key: `${prefix}Rebar1AverageDiff`,
                value: this.appendSign(`${this.invalidValueToEmpty(
                  Math.round((row.averagePitch1 - row.rebar1PitchDesign) * 10) /
                    10
                )}`),
                type: "string",
              });
            }

            if (row.importType === 0) {
              //実測値
              list.push({
                key: `${prefix}Rebar1TotalPitch`,
                value: `${row.totalPitch1}`,
                type: "string",
              });
              list.push({
                key: `${prefix}Rebar1AveragePitch`,
                value: `${row.averagePitch1}`,
                type: "string",
              });
            } else {
              //立会値
              list.push({
                key: `${prefix}Rebar1RikkaiTotalPitch`,
                value: `${row.totalPitch1}`,
                type: "string",
              });
              list.push({
                key: `${prefix}Rebar1RikkaiAveragePitch`,
                value: `${row.averagePitch1}`,
                type: "string",
              });
            }
          }
        }

        if (row.pitchList2) {
          if (row.pitchList2.length > 0) {
            list.push({
              key: `${prefix}Rebar2MeasurementItemName`,
              value: `平均間隔`,
              type: "string",
            });
            list.push({
              key: `${prefix}Rebar2Memo`,
              value: row.rebar2Memo ? row.rebar2Memo : ``,
              type: "string",
            });
            list.push({
              key: `${prefix}Rebar2No`,
              value: row.rebar2No ? row.rebar2No : ``,
              type: "string",
            });
            list.push({
              key: `${prefix}Rebar2DiameterDesign`,
              value: row.rebar2DiameterDesign
                ? `(D${row.rebar2DiameterDesign})`
                : ``,
              type: "string",
            });
            list.push({
              key: `${prefix}Rebar2DesignRangeValue`,
              value: this.appendSymbolAtFront(this.invalidValueToEmpty(rebar2DesignRangeValue), "±"),
              type: "string",
            });
            list.push({
              key: `${prefix}Rebar2LocalDesignRangeValue`,
              value: this.appendSymbolAtFront(this.invalidValueToEmpty(rebar2LocalDesignRangeValue), "±"),
              type: "string",
            });
            list.push({
              key: `${prefix}Rebar2PitchDesign`,
              value: row.rebar2PitchDesign ? row.rebar2PitchDesign : ``,
              type: "num",
            });
            list.push({
              key: `${prefix}Rebar2PitchDesignAverage`,
              value: row.rebar2PitchDesign ? row.rebar2PitchDesign : ``,
              type: "num",
            });
            if (row.rebar2PitchDesign) {
              list.push({
                key: `${prefix}Rebar2TotalPitchDesign`,
                value: `${row.pitchList2.length * row.rebar2PitchDesign}`,
                type: "string",
              });
              list.push({
                key: `${prefix}Rebar2AverageDiff`,
                value: this.appendSign(`${this.invalidValueToEmpty(
                  Math.round((row.averagePitch2 - row.rebar2PitchDesign) * 10) /
                    10
                )}`),
                type: "string",
              });
            }

            if (row.importType === 0) {
              //実測値
              list.push({
                key: `${prefix}Rebar2TotalPitch`,
                value: `${row.totalPitch2}`,
                type: "string",
              });
              list.push({
                key: `${prefix}Rebar2AveragePitch`,
                value: `${row.averagePitch2}`,
                type: "string",
              });
            } else {
              //立会値
              list.push({
                key: `${prefix}Rebar2RikkaiTotalPitch`,
                value: `${row.totalPitch2}`,
                type: "string",
              });
              list.push({
                key: `${prefix}Rebar2RikkaiAveragePitch`,
                value: `${row.averagePitch2}`,
                type: "string",
              });
            }
          }
        }

        row.pitchList1.forEach((pitch, index) => {
          list.push({
            key: `${prefix}Rebar1PitchDesign${index + 1}`,
            value: row.rebar1PitchDesign ? row.rebar1PitchDesign : ``,
            type: "string",
          });
        });
        row.pitchList2.forEach((pitch, index) => {
          list.push({
            key: `${prefix}Rebar2PitchDesign${index + 1}`,
            value: row.rebar2PitchDesign ? row.rebar2PitchDesign : ``,
            type: "string",
          });
        });

        //不要セルに斜め線を設定する
        let style = JSON.stringify({
          border: {
            diagonal: {
              up: false,
              down: true,
              style: "thin",
              color: { argb: "FF000000" },
            },
          },
        });
        for (let j = 0; j < 10; j++) {
          list.push({
            key: `${prefix}Rebar1Pitch${j + 1}`,
            value: ``,
            type: "string",
            style: style,
          });
          list.push({
            key: `${prefix}Rebar1Diff${j + 1}`,
            value: ``,
            type: "string",
            style: style,
          });
          list.push({
            key: `${prefix}Rebar2Pitch${j + 1}`,
            value: ``,
            type: "string",
            style: style,
          });
          list.push({
            key: `${prefix}Rebar2Diff${j + 1}`,
            value: ``,
            type: "string",
            style: style,
          });
        }
        list.push({
          key: `${prefix}Rebar1TotalDiff`,
          value: ``,
          type: "string",
          style: style,
        });
        list.push({
          key: `${prefix}Rebar2TotalDiff`,
          value: ``,
          type: "string",
          style: style,
        });
      } else {
        // 床板工
        list.push({
          key: `${prefix}Rebar1MeasurementItemName`,
          value: `各鉄筋間隔`,
          type: "string",
        });
        list.push({
          key: `${prefix}detailSpot`,
          value: `${row.detailSpot}`,
          type: "string",
        });
        list.push({
          key: `${prefix}Rebar1Memo`,
          value: row.rebar1Memo ? row.rebar1Memo : ``,
          type: "string",
        });
        list.push({
          key: `${prefix}Rebar1No`,
          value: row.rebar1No ? row.rebar1No : ``,
          type: "string",
        });
        list.push({
          key: `${prefix}Rebar1DiameterDesign`,
          value: row.rebar1DiameterDesign ? `(D${row.rebar1DiameterDesign})` : ``,
          type: "string",
        });
        list.push({
          key: `${prefix}Rebar1DesignRangeValue`,
          value: this.appendSymbolAtFront(this.invalidValueToEmpty(rebar1DesignRangeValue), "±"),
          type: "string",
        });
        list.push({
          key: `${prefix}Rebar1LocalDesignRangeValue`,
          value: this.appendSymbolAtFront(this.invalidValueToEmpty(rebar1LocalDesignRangeValue), "±"),
          type: "string",
        });
        list.push({
          key: `${prefix}Rebar2MeasurementItemName`,
          value: `各鉄筋間隔`,
          type: "string",
        });
        list.push({
          key: `${prefix}Rebar2Memo`,
          value: row.rebar2Memo ? row.rebar2Memo : ``,
          type: "string",
        });
        list.push({
          key: `${prefix}Rebar2No`,
          value: row.rebar2No ? row.rebar2No : ``,
          type: "string",
        });
        list.push({
          key: `${prefix}Rebar2DiameterDesign`,
          value: row.rebar2DiameterDesign ? `(D${row.rebar2DiameterDesign})` : ``,
          type: "string",
        });
        list.push({
          key: `${prefix}Rebar2DesignRangeValue`,
          value: this.appendSymbolAtFront(this.invalidValueToEmpty(rebar2DesignRangeValue), "±"),
          type: "string",
        });
        list.push({
          key: `${prefix}Rebar2LocalDesignRangeValue`,
          value: this.appendSymbolAtFront(this.invalidValueToEmpty(rebar2LocalDesignRangeValue), "±"),
          type: "string",
        });

        list.push({
          key: `${prefix}Rebar1PitchDesign`,
          value: row.rebar1PitchDesign ? row.rebar1PitchDesign : ``,
          type: "num",
        });
        list.push({
          key: `${prefix}Rebar2PitchDesign`,
          value: row.rebar2PitchDesign ? row.rebar2PitchDesign : ``,
          type: "num",
        });

        row.pitchList1.forEach((pitch, index) => {
          list.push({
            key: `${prefix}Rebar1PitchDesign${index + 1}`,
            value: row.rebar1PitchDesign ? row.rebar1PitchDesign : ``,
            type: "string",
          });
        });
        row.pitchList2.forEach((pitch, index) => {
          list.push({
            key: `${prefix}Rebar2PitchDesign${index + 1}`,
            value: row.rebar2PitchDesign ? row.rebar2PitchDesign : ``,
            type: "string",
          });
        });

        //不要セルに斜め線を設定
        let style = JSON.stringify({
          border: {
            diagonal: {
              up: false,
              down: true,
              style: "thin",
              color: { argb: "FF000000" },
            },
          },
        });
        list.push({
          key: `${prefix}Rebar1TotalPitchDesign`,
          value: ``,
          type: "string",
          style: style,
        });
        list.push({
          key: `${prefix}Rebar2TotalPitchDesign`,
          value: ``,
          type: "string",
          style: style,
        });
        list.push({
          key: `${prefix}Rebar1PitchDesignAverage`,
          value: ``,
          type: "string",
          style: style,
        });
        list.push({
          key: `${prefix}Rebar2PitchDesignAverage`,
          value: ``,
          type: "string",
          style: style,
        });

        list.push({
          key: `${prefix}Rebar1TotalPitch`,
          value: ``,
          type: "string",
          style: style,
        });
        list.push({
          key: `${prefix}Rebar2TotalPitch`,
          value: ``,
          type: "string",
          style: style,
        });
        list.push({
          key: `${prefix}Rebar1AveragePitch`,
          value: ``,
          type: "string",
          style: style,
        });
        list.push({
          key: `${prefix}Rebar2AveragePitch`,
          value: ``,
          type: "string",
          style: style,
        });

        list.push({
          key: `${prefix}Rebar1TotalDiff`,
          value: ``,
          type: "string",
          style: style,
        });
        list.push({
          key: `${prefix}Rebar2TotalDiff`,
          value: ``,
          type: "string",
          style: style,
        });
        list.push({
          key: `${prefix}Rebar1AverageDiff`,
          value: ``,
          type: "string",
          style: style,
        });
        list.push({
          key: `${prefix}Rebar2AverageDiff`,
          value: ``,
          type: "string",
          style: style,
        });
        list.push({
          key: `${prefix}Rebar1RikkaiTotalPitch`,
          value: ``,
          type: "string",
          style: style,
        });
        list.push({
          key: `${prefix}Rebar2RikkaiTotalPitch`,
          value: ``,
          type: "string",
          style: style,
        });
        list.push({
          key: `${prefix}Rebar1RikkaiAveragePitch`,
          value: ``,
          type: "string",
          style: style,
        });
        list.push({
          key: `${prefix}Rebar2RikkaiAveragePitch`,
          value: ``,
          type: "string",
          style: style,
        });

        // 床版工は各鉄筋の間隔のみ出力する。
        


        row.pitchList1.forEach((pitch, index) => {
          if (row.importType === 0) {
            //実測値
            list.push({
              key: `${prefix}Rebar1Pitch${index + 1}`,
              value: `${pitch.value}`,
              type: "string",
            });
            if (row.rebar1PitchDesign) {
              //差
              list.push({
                key: `${prefix}Rebar1Diff${index + 1}`,
                value: this.appendSign(`${Math.round((pitch.value - row.rebar1PitchDesign) * 10) / 10 }`),
                type: "string",
              });
            }
          } else {
            //立会値
            list.push({
              key: `${prefix}Rebar1Rikkai${index + 1}`,
              value: `${pitch.value}`,
              type: "string",
            });
            if (row.rebar1PitchDesign) {
              //差
              list.push({
                key: `${prefix}Rebar1Diff${index + 1}`,
                value: this.appendSign(`${Math.round((pitch.value - row.rebar1PitchDesign) * 10) / 10 }`),
                type: "string",
              });
            }
          }
        });
        row.pitchList2.forEach((pitch, index) => {
          if (row.importType === 0) {
            //実測値
            list.push({
              key: `${prefix}Rebar2Pitch${index + 1}`,
              value: `${pitch.value}`,
              type: "string",
            });
            if (row.rebar2PitchDesign) {
              list.push({
                key: `${prefix}Rebar2Diff${index + 1}`,
                value: this.appendSign(`${Math.round((pitch.value - row.rebar2PitchDesign) * 10) / 10}`),
                type: "string",
              });
            }
          } else {
            //立会値
            list.push({
              key: `${prefix}Rebar2Rikkai${index + 1}`,
              value: `${pitch.value}`,
              type: "string",
            });
            if (row.rebar2PitchDesign) {
              list.push({
                key: `${prefix}Rebar2Diff${index + 1}`,
                value: this.appendSign(`${Math.round((pitch.value - row.rebar2PitchDesign) * 10) / 10}`),
                type: "string",
              });
            }
          }
        });
      }
      if (prefixNum == 4) {
        iterateValueList.push({ list: list });
        list = [];
      }

      const rowHeightNum = 17;
      const offSet = 18;
      let rowMin = 0;
      let rowMax = 0;
      startRowMin += lastImageRow;
      let photoValues = [];
      for (let j = 0; j < row.imageList.length; j++) {
        rowMin = startRowMin * (j + 1) + offSet * j;
        rowMax = rowMin + rowHeightNum;

        //撮影日をskから取得
        // let skItems = commonAttribute.sk.split("#");
        let measurementDate = row.title;
        // let shootingDate = DateUtil.getDateString(row.title, 'YYYY年MM月DD日')
        let shootingDate = DateUtil.formatJPDateString(row.title, 'YYYY年MM月DD日')

        // 設計値の間隔合計
        const totalDesignPitch1 = MathUtil.multiply(row.rebar1PitchDesign, row.pitchList1.length)
        const totalDesignPitch2 = MathUtil.multiply(row.rebar2PitchDesign, row.pitchList2.length)
        
        photoValues.push({
          key: "measureTitle",
          value: row.importType == "0" ? "実測値" : "立会値",
          type: "string",
        });

        photoValues.push({
          key: "typeName",
          value: row.type == "0" ? "鉄筋工" : "床版工",
          type: "string",
        });
        photoValues.push({
          key: "constructionType",
          value: row.constructionType ? row.constructionType : ``,
          type: "string",
        });
        photoValues.push({
          key: "shootingSpot",
          value: row.shootingSpot,
          type: "string",
        });
        photoValues.push({
          key: "placeName",
          value: row.detailSpot,
          type: "string",
        });
        photoValues.push({
          key: "rebar1No",
          value: row.rebar1No ? row.rebar1No : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar1DiameterDesign",
          value: row.rebar1DiameterDesign ? `D${row.rebar1DiameterDesign}` : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar1PitchDesign",
          value: row.rebar1PitchDesign ? row.rebar1PitchDesign : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar1Number",
          value: row.pitchList1 ? row.pitchList1.length : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar2No",
          value: row.rebar2No ? row.rebar2No : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar2DiameterDesign",
          value: row.rebar2DiameterDesign ? `D${row.rebar2DiameterDesign}` : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar2PitchDesign",
          value: row.rebar2PitchDesign ? row.rebar2PitchDesign : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar2Number",
          value: row.pitchList2 ? row.pitchList2.length : ``,
          type: "string",
        });
        photoValues.push({
          key: "averagePitch1",
          value: this.invalidValueToEmpty(row.averagePitch1),
          type: "string",
        });
        photoValues.push({
          key: "totalPitch1",
          value: this.invalidValueToEmpty(row.totalPitch1),
          type: "string",
        });
        photoValues.push({
          key: "totalDesignPitch1",
          value: totalDesignPitch1,
          type: "string",
        });
        photoValues.push({
          key: "averagePitch2",
          value: this.invalidValueToEmpty(row.averagePitch2),
          type: "string",
        });
        photoValues.push({
          key: "totalPitch2",
          value: this.invalidValueToEmpty(row.totalPitch2),
          type: "string",
        });
        photoValues.push({
          key: "totalDesignPitch2",
          value: totalDesignPitch2,
          type: "string",
        });
        photoValues.push({
          key: "measurementDate",
          value: measurementDate,
          type: "date",
        });
        photoValues.push({
          key: "shootingDate",
          value: shootingDate,
          type: "string",
        });
        // 規格値
        photoValues.push({
          key: `designRangeValue`,
          value: this.appendSymbolAtFront(this.invalidValueToEmpty(rebar1DesignRangeValue), "±"),
          type: "string",
        });
        // 有効高さマイナス
        photoValues.push({
          key: `isMinusValidlyheight`,
          value: row.isMinusValidlyheight ? "有効高さがマイナスのため" : "",
          type: "string",
        });

        const width = row.imageList[j].width ? row.imageList[j].width : 1364;
        const height = row.imageList[j].height ? row.imageList[j].height : 765;
        let key = row.imageList[j].isOrigin ? "originalImage" : "inspectImage";
        
        // 拡張子がsvgの場合はpngに変更して帳票出力する。pngファイルは連携時に作成済みの想定
        let imageS3Key = row.imageList[j].s3key
        let imageExt = path.extname(row.imageList[j].s3key)

        if (imageExt.toLowerCase() == ".svg") {
          let base = path.dirname(row.imageList[j].s3key)
          let fileName = path.basename(row.imageList[j].s3key, imageExt)
          imageS3Key = path.join(base, `${fileName}.png`)
        }

        photoValues.push({
          key: key,
          // value: `${row.imageList[j].s3key},${width},${height}`,
          value: `${imageS3Key},${width},${height}`,
          type: "image",
        });

        // 備考
        photoValues.push({
          key: `memo`,
          value: row.memo ? row.memo : ``,
          type: "string",
        });

        formImageList.push({
          imageId: "0",
          cellRange: `B${rowMin}:B${rowMax}`,
          width: 0,
          height: 0,
          s3Key: row.imageList[j].s3key,
        });
      }
      photoValuelist.push({ list: photoValues });
      lastImageRow = rowMax;
    }

    if (list.length > 0) {
      iterateValueList.push({ list: list });
      list = [];
    }

    let currentDateString = this.getCurrentDateString("YYYYMMDDHHmmssSSS");

    this.formOutputData.formOutputSetting.templateFile = `${selectedTemplateInfo.template}`;
    this.formOutputData.formOutputSetting.outputFileName =
      currentDateString + "_" + selectedTemplateInfo.template;
    this.formOutputData.formOutputSetting.xlsxFileS3Key = "";
    this.formOutputData.formOutputSetting.workBookFileName = "";
    this.formOutputData.formOutputSetting.sheet = selectedTemplateInfo.sheet;
    this.formOutputData.formOutputSetting.imageSheet =
      selectedTemplateInfo.imageSheet;
    this.formOutputData.formOutputSetting.datalist = formDataList;
    this.formOutputData.formOutputSetting.imageList = formImageList;
    this.formOutputData.formOutputSetting.iterateValueList = iterateValueList;
    this.formOutputData.formOutputSetting.staticValueList = staticData;
    this.formOutputData.formOutputSetting.photoValuelist = photoValuelist;
    this.formOutputData.ttl = DateUtil.getTtl();

    //帳票送信時の画像パスにてsvgがある場合、拡張子をpngに変更する。
    // for (let k=0;k<this.formOutputData.formOutputSetting.imageList.length;k++)
    // {
    //   let file_type = path.extname(this.formOutputData.formOutputSetting.imageList[k].s3Key);
    //   let file_name = path.dirname(this.formOutputData.formOutputSetting.imageList[k].s3Key);
    //   if (file_type == ".svg")
    //   {
    //     const filename = path.basename(this.formOutputData.formOutputSetting.imageList[k].s3Key, file_type);
    //     this.formOutputData.formOutputSetting.imageList[k].s3Key = path.join(file_name, filename+ ".png");
    //   } else {

    //   }
    // }
  }
}

module.exports = MiltFormCreator;