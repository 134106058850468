const DateUtil = require("../common/DateUtil");
const BaseFormCreator = require("./BaseFormCreator");
const path = require("path");
const { v4: uuidv4 } = require("uuid");
/**
 * 国土交通省（重ね継手長）の帳票を作成するクラス
 */
class MiltFormKaburiCreator4 extends BaseFormCreator {
  constructor() {
    super();
  }
  makeFormData(selectedDataList, selectedTemplateInfo) {
    // 帳票出力用データを作成
    let formDataList = [];
    let iterateValueList = [];

    let formImageList = [];
    let photoValuelist = [];

    // 写真の出力開始行(とりあえず固定)
    let startRowMin = 4;
    let lastImageRow = 0;

    // 静的に配置するデータを取得
    let staticData = this.getStaticData(selectedDataList[0]);

    let list = [];
    //差の帳票データ出力用
    let diff = [];
    //測点の帳票データ出力用
    let spot = [];

    //規格値リスト
    let kikakuti = [];

    let row = selectedDataList[0].rebarmeasure;

    // 測定項目名
    list.push({
      key: `No1MeasurementItemName`,
      value: `かぶり`,
      type: ``,
    });

    //データA社内規格値
    let rebar1LocalDesignRangeValue = this.getKaburiDesignRangeList(
      row.localDesignRangeClass,
      row.localDesignRangeValue,
      row.designValue
    );
    console.log(`社内規格値 ${rebar1LocalDesignRangeValue}`);
    //データA規格値
    let rebar1DesignRangeValue = this.getKaburiDesignRangeList(
      row.designRangeClass,
      row.designRangeValue,
      row.designValue
    );
    console.log(`規格値 ${rebar1DesignRangeValue}`);

    list.push({
      key: `No1row1spot`,
      value: ``,
      type: "string",
    });
    list.push({
      key: `No1row2spot`,
      value: `平均値`,
      type: "string",
    });
    list.push({
      key: `No1row3spot`,
      value: `最大値`,
      type: "string",
    });
    list.push({
      key: `No1row4spot`,
      value: `最小値`,
      type: "string",
    });
    list.push({
      key: `No1row5spot`,
      value: `最多値`,
      type: "string",
    });
    list.push({
      key: `No1row7spot`,
      value: `標準偏差`,
      type: "string",
    });

    for (let i = 0; i < selectedDataList.length; i++) {
      // かぶりは1測定に1データのみ。
      // let row = selectedDataList[i];
      let commonAttribute = selectedDataList[i];
      let row = selectedDataList[i].rebarmeasure;

      let prefixNum = 8 - (8 - (i % 8)) + 1;
      let datarow = `row${i + 1}`;

      let prefix = `No${prefixNum}`;
      // 測定項目名
      list.push({
        key: `${prefix}MeasurementItemName`,
        value: `重ね継手長`,
        type: ``,
      });

      kikakuti.push(row.designRangeValue);
      if (i == 0) {
        //データA規格値
        let rebar1DesignRangeValue = row.designRangeValue
          ? row.designRangeValue
          : ``;
        console.log(`規格値 ${rebar1DesignRangeValue}`);
        //データA社内規格値
        let rebar1LocalDesignRangeValue = row.localDesignRangeValue
          ? row.localDesignRangeValue
          : ``;
        console.log(`社内規格値 ${rebar1LocalDesignRangeValue}`);
        // 規格値
        list.push({
          key: `No1Rebar1DesignRangeValue`,
          value: rebar1DesignRangeValue,
          type: "string",
        });
        // 社内規格値
        list.push({
          key: `No1Rebar1LocalDesignRangeValue`,
          value: rebar1LocalDesignRangeValue,
          type: "string",
        });

        let miricheck=parseInt(rebar1DesignRangeValue);
        let miricheck2=parseInt(rebar1LocalDesignRangeValue);

        //規格値が非数の場合空にする。
        if (isNaN(miricheck)) {
          list.push({
            key: `miri`,
            value: "",
            type: "string",
          });
        }else{
          list.push({
            key: `miri`,
            value: "mm",
            type: "string",
          });
        }

        //社内規格値が非数の場合空にする。
        if (isNaN(miricheck2)) {
          list.push({
            key: `miri2`,
            value: "",
            type: "string",
          });
        }else{
          list.push({
            key: `miri2`,
            value: "mm",
            type: "string",
          });
        }
      }

      // 測点
      let shootingSpotNameValue = row.shootingSpot ? row.shootingSpot : ``;
      if (
        row.rebar1No &&
        row.rebar1DiameterDesign &&
        row.rebar2No &&
        row.rebar2DiameterDesign
      ) {
        shootingSpotNameValue = `${shootingSpotNameValue} ${row.rebar1No}(D${row.rebar1DiameterDesign})*${row.rebar2No}(D${row.rebar2DiameterDesign})`;
      }

      list.push({
        key: `No2${datarow}spot`,
        value: shootingSpotNameValue,
        type: `string`,
      });

      // 鉄筋番号１
      list.push({
        key: `${prefix}Rebar1No`,
        value: row.rebar1No ? row.rebar1No : ``,
        type: "string",
      });
      // 設計径１
      list.push({
        key: `${prefix}Rebar1DiameterDesign`,
        value: row.rebar1DiameterDesign ? `D${row.rebar1DiameterDesign}` : ``,
        type: "string",
      });
      // 鉄筋番号２
      list.push({
        key: `${prefix}Rebar2No`,
        value: row.rebar2No ? row.rebar2No : ``,
        type: "string",
      });
      // 設計径２
      list.push({
        key: `${prefix}Rebar2DiameterDesign`,
        value: row.rebar2DiameterDesign ? `D${row.rebar2DiameterDesign}` : ``,
        type: "string",
      });

      // かぶり測定結果はpitchListに1要素のみ
      // 設計値
      list.push({
        key: `No2${datarow}Design`,
        value: row.designValue ? row.designValue : ``,
        type: "string",
      });
      // 実測値
      list.push({
        key: `No2${datarow}value`,
        value: `${row.value}`,
        type: "string",
      });
      // 誤差
      if (row.value && row.designValue) {
        list.push({
          key: `No2${datarow}diff`,
          value: `${this.appendSign(`${(row.value - row.designValue).toFixed(1)}`)}`,
          type: "string",
        });
      }
      diff.push(
        Number(this.appendSign(`${(row.value - row.designValue).toFixed(1)}`))
      );
      spot.push(`${shootingSpotNameValue}`);
      // });

      //不要セルに斜め線を設定する
      let style = JSON.stringify({
        border: {
          diagonal: {
            up: false,
            down: true,
            style: "thin",
            color: { argb: "FF000000" },
          },
        },
      });

      if (prefixNum == 10) {
        iterateValueList.push({ list: list });
        list = [];
      }

      const rowHeightNum = 17;
      const offSet = 18;
      let rowMin = 0;
      let rowMax = 0;
      startRowMin += lastImageRow;
      let photoValues = [];
      for (let j = 0; j < row.imageList.length; j++) {
        rowMin = startRowMin * (j + 1) + offSet * j;
        rowMax = rowMin + rowHeightNum;

        //撮影日をskから取得
        // let skItems = commonAttribute.sk.split("#");
        let measurementDate = row.title;
        // let shootingDate = DateUtil.getDateString(row.title, 'YYYY年MM月DD日')
        let shootingDate = DateUtil.formatJPDateString(
          row.title,
          "YYYY年MM月DD日"
        );

        photoValues.push({
          key: "measureTitle",
          value: row.importType == "0" ? "実測値" : "立会値",
          type: "string",
        });

        photoValues.push({
          key: "typeName",
          value: row.type == "0" ? "鉄筋工" : "床版工",
          type: "string",
        });
        photoValues.push({
          key: "constructionType",
          value: row.constructionType ? row.constructionType : ``,
          type: "string",
        });
        photoValues.push({
          key: "shootingSpot",
          value: row.shootingSpot,
          type: "string",
        });
        photoValues.push({
          key: "placeName",
          value: row.detailSpot,
          type: "string",
        });
        photoValues.push({
          key: "rebar1No",
          value: row.rebar1No ? row.rebar1No : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar1DiameterDesign",
          value: row.rebar1DiameterDesign ? `D${row.rebar1DiameterDesign}` : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar2No",
          value: row.rebar2No ? row.rebar2No : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar2DiameterDesign",
          value: row.rebar2DiameterDesign ? `D${row.rebar2DiameterDesign}` : ``,
          type: "string",
        });
        photoValues.push({
          key: "rebar1PitchDesign",
          value: row.rebar1PitchDesign,
          type: "string",
        });

        photoValues.push({
          key: "measurementDate",
          value: measurementDate,
          type: "date",
        });
        photoValues.push({
          key: "shootingDate",
          value: shootingDate,
          type: "string",
        });

        // 規格値
        photoValues.push({
          key: `designRangeValue`,
          value: row.designRangeValue ? row.designRangeValue : "",
          type: "string",
        });

        // 設計値
        photoValues.push({
          key: `design`,
          value: row.designValue ? `${row.designValue}` : ``,
          type: "string",
        });
        // 実測値
        photoValues.push({
          key: `value`,
          value: `${row.value}`,
          type: "string",
        });
        // 誤差
        if (row.value && row.designValue) {
          photoValues.push({
            key: `diff`,
            value: `${this.appendSign(`${row.value - row.designValue}`)}`,
            type: "string",
          });
        }
        // 備考
        photoValues.push({
          key: `memo`,
          value: row.memo ? row.memo : ``,
          type: "string",
        });

        const width = row.imageList[j].width ? row.imageList[j].width : 1364;
        const height = row.imageList[j].height ? row.imageList[j].height : 765;
        let key = row.imageList[j].isOrigin ? "originalImage" : "inspectImage";
        let imageS3Key = row.imageList[j].s3key;
        let imageExt = path.extname(row.imageList[j].s3key);

        // 拡張子がsvgの場合はpngに変更して帳票出力する。pngファイルは連携時に作成済みの想定
        if (imageExt.toLowerCase() == ".svg") {
          let base = path.dirname(row.imageList[j].s3key);
          let fileName = path.basename(row.imageList[j].s3key, imageExt);
          imageS3Key = path.join(base, `${fileName}.png`);
        }

        photoValues.push({
          key: key,
          // value: `${row.imageList[j].s3key},${width},${height}`,
          value: `${imageS3Key},${width},${height}`,
          type: "image",
        });
        // formImageList.push({
        //   imageId: "0",
        //   cellRange: `B${rowMin}:B${rowMax}`,
        //   width: 0,
        //   height: 0,
        //   s3Key: row.imageList[j].s3key,
        // });

        formImageList.push({
          imageId: "0",
          cellRange: `B${rowMin}:B${rowMax}`,
          width: 0,
          height: 0,
          s3Key: row.imageList[j].s3key,
        });
      }
      photoValuelist.push({ list: photoValues });
      lastImageRow = rowMax;
    }

    //平均
    list.push({
      key: `No1row2diff`,
      value: this.average(diff),
      type: "string",
    });
    //最大値
    list.push({
      key: `No1row3diff`,
      value: this.max(diff),
      type: "string",
    });
    //最小値
    list.push({
      key: `No1row4diff`,
      value: this.min(diff),
      type: "string",
    });
    //最多値
    list.push({
      key: `No1row5diff`,
      value: this.mode(diff),
      type: "string",
    });
    list.push({
      key: `No1row6diff`,
      value: "n=" + selectedDataList.length,
      type: "string",
    });
    //標準偏差
    list.push({
      key: `No1row7diff`,
      value: "m=±" + this.standardDeviation(diff),
      type: "string",
    });

    if (list.length > 0) {
      iterateValueList.push({ list: list });
      list = [];
    }

    const mojisu = 15;
    //グラフ画像に表示する長文字をチェックする。
    for (let i = 0; i < spot.length; i++) {
      if (spot[i].length > mojisu) {
        //15文字超えたら文字末尾...にする。
        spot[i] = spot[i].substring(0, mojisu) + "...";
      }
    }

    //グラフ作成用JSONパラメータ作成処理
    let myChart = this.createJson(diff, spot, kikakuti[0]);

    let jsonname = uuidv4();
    //JSONアップロード処理
    this.uploadJson(myChart, jsonname);

    staticData.push({
      key: "graph",
      value: `${jsonname}`,
      type: "graph",
    });

    let currentDateString = this.getCurrentDateString("YYYYMMDDHHmmssSSS");

    this.formOutputData.formOutputSetting.templateFile = `${selectedTemplateInfo.template}`;
    this.formOutputData.formOutputSetting.outputFileName =
      currentDateString + "_" + selectedTemplateInfo.template;
    this.formOutputData.formOutputSetting.xlsxFileS3Key = "";
    this.formOutputData.formOutputSetting.workBookFileName = "";
    this.formOutputData.formOutputSetting.sheet = selectedTemplateInfo.sheet;
    this.formOutputData.formOutputSetting.imageSheet =
      selectedTemplateInfo.imageSheet;
    this.formOutputData.formOutputSetting.datalist = formDataList;
    this.formOutputData.formOutputSetting.imageList = formImageList;
    this.formOutputData.formOutputSetting.iterateValueList = iterateValueList;
    this.formOutputData.formOutputSetting.staticValueList = staticData;
    this.formOutputData.formOutputSetting.photoValuelist = photoValuelist;
    this.formOutputData.ttl = DateUtil.getTtl();

    //帳票送信時の画像パスにてsvgがある場合、拡張子をpngに変更する。
    // for (let k=0;k<this.formOutputData.formOutputSetting.imageList.length;k++)
    // {
    //   let file_type=path.extname(this.formOutputData.formOutputSetting.imageList[k].s3Key);
    //   let file_name=path.dirname(this.formOutputData.formOutputSetting.imageList[k].s3Key);
    //   if (file_type==".svg")
    //   {
    //     const filename = path.basename(this.formOutputData.formOutputSetting.imageList[k].s3Key, file_type);
    //     this.formOutputData.formOutputSetting.imageList[k].s3Key=path.join(file_name, filename+ ".png");
    //   }
    // }
  }
}

module.exports = MiltFormKaburiCreator4;
